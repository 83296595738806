<template>
    <PageHeader :visible="header_visible" :buttons="header_buttons" :switches="header_switches"></PageHeader>
  <AntTable :source="report" @command="table_command"></AntTable>
</template>

<script>
import {defineComponent, ref, inject, reactive, toRefs } from 'vue';
import AntTable from "@/components/Layout/AntTable.vue";
import PageHeader from "@/components/Layout/PageHeader";


export default defineComponent({
  props:{
    app: {type: String, default: ''},
    model: {type: String, default: ''},
    report_name: {type: String, default: ''},
    filters: {type: Object, default: ()=>({})},
    sorting: {type: Object, default: ()=>({})},
    allow_add: {type: Boolean, default: false},
    route_name_for_add: {type: String, default: ''},
    show_history: {type: Boolean, default: false},
    parent_keys: {type: Object, default: ()=>({})},
    header_visible: {type: Boolean, default: false},
    header_buttons: {type: Array, default: ()=>([])},
    header_switches: {type: Array, default: ()=>([])},
    header_bn_refresh: {type: Boolean, default: true},
    header_sw_history: {type: Boolean, default: false},
  },

  components: {
    AntTable,
    PageHeader,
  },

  setup(props) {
    const {header_buttons, header_switches, show_history} = toRefs(props)
    const utils = inject('utils');
    const message = inject('message');
    const report = ref(utils.table.new_data(props.filters, props.sorting));
    report.value.show_history = show_history.value
    console.log('obj list props', props)
    refresh(report.value.default_filters, report.value.default_sorting)


    if (props.header_bn_refresh){
      header_buttons.value.push(utils.dom.button(refresh,'bn.refresh','refresh','bn.refresh','',''))
    }

    if (props.header_sw_history){
      header_switches.value.push(utils.dom.switch(report.value.show_history, bnHistory, 'bn.history', 'bn.history'))
    }


    function bnHistory(show_history=false){
      console.log('bnHistory', show_history)
      report.value.show_history = show_history
      refresh()
    }

    function add_obj(){
      if (props.route_name_for_add){
        utils.router.push(props.route_name_for_add, {id: 0});
        return;
      }

      let new_item = reactive({id:0})

      for(let key in props.parent_keys) {new_item[key] = props.parent_keys[key];}

      utils.api.save(props.app, props.model, new_item)
          .then(()=>{message.success('item added'); refresh();})
          .catch(()=>{message.error('add item failed')})
    }

    function copy_obj(sourceObj){
      let new_obj = reactive({id:0})
      utils.dict.sync(new_obj, sourceObj)
      new_obj.id = 0;
      new_obj.remark = new_obj.remark + ' (copy)'

      utils.api.save_obj(props.app, props.model, new_obj)
          .then(()=>{
            message.warning('item copied')
            refresh()
          })
      console.log(sourceObj)
    }


    function update_obj(item){
      utils.api.save_obj(props.app, props.model, item)
          .then(()=>{message.success('saved')})
          .catch(()=>{message.error('save failed')})
          .finally(()=>{refresh()})
    }

    function del_obj(item){
      if (!item.id){return;}

      utils.api.del_obj(props.app, props.model, item.id)
          .then(()=>{refresh();message.success('deleted')})
          .catch(()=>{message.error('delete failed')})
    }

    function refresh(filters=report.value.filters, sorting=report.value.sorting){
      report.value.filters=filters;
      report.value.sorting=sorting;
      report.value.filters.__show_history = (report.value.show_history)? 1 : 0

      for(let key in props.parent_keys) {report.value.filters[key] = props.parent_keys[key];}

      let params = utils.table.api_para(report)
      console.log('todolist for_all_events', params)
      report.value.is_loading= true;

      utils.api.get_list(props.app, props.model, props.report_name, params)
          .then(response =>{
            utils.table.set_data(report, response, refresh);

            for (let k=0;k< report.value.columns.length;k++){
              let column = report.value.columns[k]

              switch (column.key){
                case 'bnCopy':
                  utils.table.column_set_button(column, 'copy', '', 'bn.copy')
                  break;
                case 'bnDel':
                  utils.table.column_set_button(column, 'del', 'message.confirm_delete', 'bn.delete')
                  if (props.allow_add){
                    utils.table.column_set_header_button(column, 'add', '', 'bn.add')
                  }
                  break;
              }
            }
          })
          .catch(()=>{
            report.value = utils.table.new_data();
          })
          .finally(()=>{
            report.value.is_loading= false;
          })
    }


    function table_command(command){
      switch (command.column) {
        case 'refresh':refresh(command.filters, command.sorting);break;
        case 'done':if (command.isheader){console.log('command.isheader', command.isheader)}else {update_obj(command.obj);}break;
        case 'bnCopy':if (command.obj.id){copy_obj(command.obj)} break;
        case 'bnDel':if (command.isheader){add_obj();}else{if (command.obj.id){del_obj(command.obj);}}break;
        default:
          console.log('command.column', command.column)
          switch (command.column.control){
            case 'link':
              utils.router.push(command.column.route?command.column.route: props.route_name_for_add, {id: command.column.link_pk? command.obj[command.column.link_pk]: command.obj.id});break;
            default: if (command.obj.id){update_obj(command.obj);} break;
          }
      }
    }


    return{
      report,
      table_command,
    }
  }
})

</script>

<style scoped>

</style>