<template>
  <a-page-header class="page_header" v-if="visible"
    :title="document.title"
    sub-title=""
    @back="utils.router.go_back()">

      <template #extra>
        <IconButton v-for="(obj, key) in buttons" :key="key" :config="obj"/>
        <a-switch v-for="(obj, key) in switches" :key="key"
                  v-model:checked="obj.checked.value"
                  @click="switch_click(obj)"
                  :checked-children="obj.true_text===''?'':$t(obj.true_text)"
                  :un-checked-children="obj.false_text===''?'':$t(obj.true_text)" />
      </template>

  </a-page-header>
</template>

<script>
import {defineComponent, inject,} from "vue";
import IconButton from "@/components/Layout/IconButton";

export default defineComponent({
  name: "PageHeader",

  props:{
    visible: {type: Boolean, default: false},
    buttons: {type: Array, default: ()=>([])},
    switches: {type: Array, default: ()=>([])},
  },

  components:{
    IconButton,
  },

  setup(props){
    const utils = inject('utils')
    console.log('header', props)
    function switch_click(obj){
      if(typeof(obj.click)==='function'){obj.click(obj.checked);}
    }

    return {
      utils,
      document,
      switch_click,
    }
  }
})
</script>

<style scoped>

.page_header{
  border: 1px solid rgb(235, 237, 240);
  margin-bottom: 5px;
}

</style>