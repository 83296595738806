<template>
<a-tooltip v-if="config !== null" placement="bottom">
  <template #title><span v-if="config.hint">{{$t(config.hint)}}</span></template>

    <a-button v-if="config.popconfirm === ''" :shape="config.shape" :size="config.size" :ghost="config.ghost" @click="click">
      <img v-if="config.icon" :src="icons[config.icon]" class="button_icon"/>
      <span v-if="config.text">{{$t(config.text)}}</span>
    </a-button>

    <a-popconfirm v-else :title="$t(config.popconfirm)" @confirm="click">
      <a-button :shape="config.shape" :size="config.size" :ghost="config.ghost">
        <img v-if="config.icon" :src="icons[config.icon]" class="button_icon"/>
        <span v-if="config.text">{{$t(config.text)}}</span>
      </a-button>
    </a-popconfirm>

</a-tooltip>
</template>

<script>
import {defineComponent} from 'vue'
import icons from "@/utils/icons";


export default defineComponent({
  name: "IconButton",
  props:{
    ghost: {type:Boolean, default: true},
    config: {type:Object, default: null},
  },
  emits: ['click'],
  setup(props, context) {

    const click = () => {
      if (props.config !== null && typeof(props.config.fn)==='function'){
        props.config.fn();
      }else{
        context.emit('click');
      }
    };

    return {
      click,
      icons,
    }
  }
})
</script>

<style scoped>
.button_icon{
  height: 22px;
  margin-top:-3px;
}
</style>