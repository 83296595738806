<template>

<div class="ant-table ant-table-bordered" v-if="source">
   <h1 v-if="source.title" style="text-align: center;"> {{ source.title }} </h1>
   <h1 v-if="source.show_refresh_time" style="text-align: center;">
     更新时间：{{ utils.datetime.localtime(source.time) }} &nbsp;&nbsp; <a-button @click="header_command('refresh')" :loading="source.is_loading">刷新</a-button>
   </h1>

  <div class="ant-table-container">
    <div class="ant-table-content">
      <table style="table-layout: auto;">
        <thead class="ant-table-thead">
          <tr>
            <th class="ant-table-cell" colstart="0" colend="0" v-for="column in source.columns" :key="column.key" :width="column.width + '%'">
              <div v-if="column.head_control === 'switch'">
                <a-switch v-model:checked="column.head_checked"
                          @click="header_command(column.key, column.head_checked)"
                          :checked-children="column.head_true_text"
                          :un-checked-children="column.head_false_text" />
              </div>
              <div v-else-if="column.head_control === 'button'">
                <IconButton :config="utils.dom.button(null,'',column.head_icon,column.head_hint,column.head_popconfirm)"
                            @click="header_command(column.key)">
                </IconButton>
              </div>
              <div v-else-if="column.key === 'number'" class="header_cell">
                <a-space class="sorting" @click="change_filter_status">
                {{$t('field.'+column.title)}}
                  <img v-if="!filter_enabled" :src="utils.icons.filter" class="header_icon">
                  <img v-if="filter_enabled" :src="utils.icons.filter_clear" class="header_icon">
                  </a-space>
              </div>
                <span v-else-if="column.sorting" class="header_cell">
                  <a-space class="sorting" @click="sort(column)">
                    {{$t('field.'+column.title)}}
                    <img v-if="sorting[column.key]===1" :src="utils.icons.sorting_up" class="header_icon">
                    <img v-else-if="sorting[column.key]===-1" :src="utils.icons.sorting_down" class="header_icon">
                    <img v-else :src="utils.icons.sorting" class="header_icon no_sorting">
                  </a-space>
                </span>

                <span v-else class="header_cell">
                   {{$t('field.'+column.title)}}
                </span>



            </th>
          </tr>
          <tr v-if="filter_enabled" class="filter_row">
            <td v-for="column in source.columns" :key="column.key" class="filter_cell">
              <div v-if="column.key === 'number'">
                <IconButton :config="utils.dom.button(reset_filters,'', 'previous_alt', 'bn.reset_filter','')" />
              </div>
            <div v-else-if="!column.filters"></div>
            <a-range-picker v-else-if="column.datatype.startsWith('Date')" v-model:value="filters[column.key]"
                           @change="refresh" format="M月D" class="filter_control"/>

            <a-select v-else-if="column.choices"  v-model:value="filters[column.key]"
                      :options="utils.dom.filter_options(column)"
                      :filter-option="utils.dom.select_filterOption"
                      @change="refresh"
                      placeholder="-"
                      show-search
                      class="filter_control"/>

            <a-select v-else-if="utils.table.datatype_is_bool(column)"
                      v-model:value="filters[column.key]"
                      :options="utils.dom.filter_options(column)"
                      @change="refresh"
                      placeholder="-"
                      class="filter_control"/>

            <a-input-number v-else-if="utils.table.datatype_is_int(column)" :precision="0"
                            v-model:value="filters[column.key]" @pressEnter="refresh" class="filter_control"/>
            <a-input-number v-else-if="utils.table.datatype_is_float(column)"
                            v-model:value="filters[column.key]" @pressEnter="refresh" class="filter_control"/>
            <a-input v-else v-model:value="filters[column.key]" @pressEnter="refresh" class="filter_control" />
          </td>
        </tr>
        </thead>
        <tbody class="ant-table-tbody">
          <tr class="ant-table-row ant-table-row-level-0"  v-for="(obj, key) in source.data" :key="obj.id">
            <td v-for="column in source.columns" :key="column.key">
              <div v-if="column.key === 'number'" class="readonly-cell">
                {{ source.paginator.start_number + key }}
              </div>

              <IconButton v-else-if="column.control === 'button'"
                          :config="utils.dom.button(null,'',column.icon,column.hint,column.popconfirm)"
                          @click="cell_command(column.key, obj)" />

              <a-button v-else-if="column.control === 'link'" type="link" @click="cell_command(column, obj)">{{obj[column.key]}}</a-button>

              <a-switch v-else-if="utils.table.switch_cell(column, obj)"
                          v-model:checked="obj[column.key]"
                           @click="cell_command(column.key, obj)"
                          :checked-children="utils.dom.select_Option_label(column, true, $t) "
                          :un-checked-children="utils.dom.select_Option_label(column, false, $t) " />

              <a-select v-else-if="utils.table.select_cell(column, obj)" v-model:value="obj[column.key]"
                        :options="utils.dom.select_options(column, null,false)"
                        :filter-option="utils.dom.select_filterOption"
                        @change="cell_command(column.key, obj)"
                        show-search
                        style="width: 100%;"/>

              <div v-else-if="utils.table.cell_editable(column, obj)" class="editable-cell">
                  <div v-if="is_editing_cell(obj.id,column.key)" class="editable-cell-input-wrapper">
                    <a-space>
                    <a-date-picker v-if="utils.table.datatype_is_datetime(column)" v-model:value="editing_cell.value"
                                   @ok="save_cell(column, obj)"
                                   show-time :allowClear="false" ref="editing" />
                    <a-input-number v-else-if="utils.table.datatype_is_int(column)" :precision="0" ref="editing"
                                    v-model:value="editing_cell.value" @pressEnter="save_cell(column, obj)"/>
                    <a-input-number v-else-if="utils.table.datatype_is_float(column)" ref="editing"
                                    v-model:value="editing_cell.value" @pressEnter="save_cell(column, obj)"/>
                    <a-input v-else ref="editing" v-model:value="editing_cell.value" @pressEnter="save_cell(column, obj)"/>
                    <img :src="utils.icons.ok" class="bn_icon" @click="save_cell(column, obj)">
                    <img :src="utils.icons.close" class="bn_icon" @click="edit_cell_cancel">
                    </a-space>
                  </div>
                  <div v-else class="editable-cell-text-wrapper">
                    <a-space>
                      <span v-if="utils.table.datatype_is_datetime(column)" >{{ utils.moment(obj[column.key]).format('yyyy-MM-DD HH:mm:ss') }}</span>
                      <span v-else>{{ obj[column.key] }}</span>
                      <img :src="utils.icons.edit" class="bn_icon hidden" @click="edit_cell(column, obj)">
                    </a-space>
                  </div>
              </div>

              <div v-else class="readonly-cell">
                      <span v-if="utils.table.datatype_is_datetime(column)">{{ utils.moment(obj[column.key]).format('yyyy-MM-DD HH:mm:ss') }}</span>
                      <span v-else-if="column.choices" >{{ utils.dom.select_Option_label(column, obj, $t) }}</span>
                      <span v-else-if="utils.table.datatype_is_bool(column)" >{{ utils.dom.select_Option_label(column, obj, $t) }}</span>
                      <span v-else>{{ obj[column.key] }}</span>
              </div>
            </td>
          </tr>
        </tbody>


        <tfoot v-if="source.sum_row" class="sum_row">
          <tr>
            <td v-for="column in source.columns" :key="column.key">
                {{ source.sum_row[column.key] }}
            </td>
          </tr>
        </tfoot>


      </table>
      </div>
  </div>
  <p></p>
  <a-row type="flex">
    <a-col flex="300px" class="footer_time">更新时间：{{ utils.datetime.localtime(source.time) }}</a-col>
    <a-col flex="auto">
    <div style="text-align: right">
      <a-pagination
         v-model:current="paginator.current"
         v-model:total="paginator.total"
         v-model:page-size="paginator.size"
         @change="header_command('refresh')"
         @showSizeChange="header_command('refresh')"
         :show-total="(total, range) => `第${range[0]}-${range[1]}， 共${total}`"
         showQuickJumper
         show-size-changer/>
    </div>
    </a-col>
  </a-row>
</div>
</template>

<script>
import {defineComponent, inject, reactive, ref, toRefs, watch} from 'vue';
import IconButton from "@/components/Layout/IconButton.vue";


export default defineComponent({
  props:{
    source: {type:Object},
  },
  components:{
    IconButton,
  },
  emits: ['command'],


  setup(props, context) {
    const {source} = toRefs(props)
    const utils = inject('utils');
    const paginator = ref(utils.table.new_data().paginator);
    const filters = ref(source.value.default_filters);
    const default_filters = ref(source.value.default_filters);
    const sorting = ref(source.value.default_sorting);
    // const default_sorting = ref(source.value.default_sorting);

    const filter_enabled = ref(false);
    watch(source,() => {
      paginator.value = source.value.paginator
      filters.value = source.value.filters
      sorting.value = source.value.sorting
      // console.log('ant table watch', source.value)
    })

    function command(column, isheader=false, obj=null){
      let command_detail = {column:column, isheader:isheader, obj:obj}
      if (isheader){
        command_detail['paginator']=paginator
        // if (filter_enabled.value){        }
        command_detail['filters']=filters.value
        command_detail['sorting']=sorting.value
      }
      console.log('AntTable', command_detail)
      context.emit('command', command_detail)
    }

    function header_command(column, obj=null){
      command(column, true, obj)
    }

    function cell_command(column, obj=null){
      command(column, false, obj)
    }

    function refresh(){
      header_command('refresh')
    }

    function change_filter_status(){
      filter_enabled.value = !filter_enabled.value;
    }

    function reset_filters(){
      filters.value = default_filters.value;
      console.log('reset_filters',  filters.value )
      refresh();
    }

    function sort(column){
      let current_tag = sorting.value[column.key]
      delete sorting.value[column.key];

      switch (current_tag){
        case 1:sorting.value[column.key] = -1;break;
        case -1: break;
        default:sorting.value[column.key] = 1;break;
      }
      header_command('refresh')
    }

    const editing_cell = reactive({id:-1, field:'', value:null});

    function edit_cell(column, obj){
      console.log(column.datatype)
      if (utils.table.datatype_is_datetime(column)){
        editing_cell.value = utils.moment(obj[column.key]);
      }else{
        editing_cell.value = obj[column.key];
      }
      editing_cell.id=obj.id;
      editing_cell.field=column.key;

      utils.dom.focus_el_nextTick(this, 'editing');
    }

    function save_cell(column, obj){
      let field = column.key
      if (obj.id === editing_cell.id && field === editing_cell.field){
        if (String(editing_cell.value).trim() === ''){
          if (utils.table.datatype_is_float(column) || utils.table.datatype_is_int(column)){
            editing_cell.value=0;
          }
        }

        obj[field] = editing_cell.value
        edit_cell_cancel();
        cell_command(field, obj);
      }else{
        edit_cell_cancel();
      }
    }

    function edit_cell_cancel(){
      editing_cell.id=-1;
      editing_cell.field='';
      editing_cell.value = null
    }

    function is_editing_cell(id, field){
      return (editing_cell.id===id && editing_cell.field===field);
    }

    return{
      paginator,
      filter_enabled,
      filters,
      reset_filters,
      change_filter_status,
      sort,
      sorting,
      header_command,
      cell_command,
      refresh,
      utils,
      editing_cell,
      edit_cell,
      save_cell,
      edit_cell_cancel,
      is_editing_cell,
    }
  }
})
</script>

<style scoped>
.footer_time{
  color: darkgray;
}

.filter_row{
  background-color: lightyellow;
  height: 0.3em;
  line-height: 0.3em;
}

.filter_cell{
  background-color: lightyellow;
}

.filter_control{
  background-color: lightyellow;
  color: orangered;
  width: 100%;
}

.bn_icon{
  height: 20px;
  cursor: pointer;
  color: darkgreen;
}

.header_cell{
  font-weight: bold;
}

.header_cell .sorting{
  cursor: pointer;
}

.header_cell .sorting:hover .no_sorting{
  display: block;
}

.no_sorting{
  display: none;
}

.header_icon{
  height: 15px;
  width: 15px;
}


.hidden{
  display: none;
}

.editable-cell{
}

.editable-cell-text-wrapper{
color: darkgreen;
}

.editable-cell-text-wrapper:hover .hidden{
  display: block;
}

.readonly-cell{
  color:black;
}

.sum_row{
  font-weight: bold;
  text-align: center;
  height: 2em;
  border-width: 1px;
  border-color: lightgrey;
  background-color: whitesmoke;
  border-style: solid;
}

.sum_row tr td{

}

</style>

